@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Itim";
    src: local("Itim-Regular"), url("../fonts/Itim-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "GillSansMT";
    src: local("Gill-Sans-MT"), url("../fonts/Gill-Sans-MT.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url("../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url("../fonts/Montserrat.ttf") format("truetype");
}

.upgradeToPro{
    background: linear-gradient(278deg, #00FFA3 0%, #0128FF 102.13%);
}

.lidabro-gradient{
    background: linear-gradient(278deg, #00FFA3 0%, #0128FF 102.13%);
}
.lidabro-shop-gradient{
    background: linear-gradient(26deg, #F8FBFF 2.75%, #FDFDFF 81.02%);
}
.lidabro-text-gradient{
    background: linear-gradient(46deg, #00FFA3 -66.24%, #0128FF 385.48%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.lidabro-gradient-eyezone{
    background: var(--Lidabro-bluere, linear-gradient(278deg, #00FFA3 0%, #0128FF 102.13%));
}
.lidabro-button-shadow{
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}